import { AdminUpdateContractDto, ContractResponseDto } from '@/api/generated';
import { COLORS } from '@/constants';
import { adminUpdateContractThunk } from '@/store/ducks/admin/thunks';
import { useAppDispatch } from '@/store/hooks';
import { Button, Input } from '@/ui/components';
import currency from 'currency.js';
import React, { FC, useState } from 'react';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';

interface ContractItemProps {
  data: ContractResponseDto;
}

interface FormData {
  sum: string;
}

const ContractItem: FC<ContractItemProps> = ({ data }) => {
  const dispatch = useAppDispatch();
  const { user } = data;
  const userFullName = `${user.lastName} ${user.firstName} ${user.middleName}`;
  const [isOpenForm, setIfOpenForm] = useState(false);

  const {
    handleSubmit,
    formState: { isSubmitting, isValid, isDirty },
    register,
    reset,
  } = useForm<FormData>({
    mode: 'onChange',
  });

  const onSubmit = handleSubmit(async (values) => {
    const neededData: AdminUpdateContractDto = {
      id: data.id,
      sum: values.sum,
    };

    try {
      await dispatch(adminUpdateContractThunk(neededData)).unwrap();
      setIfOpenForm(false);
      reset();
    } catch (rejectedValueOrSerializedError) {}
  });

  return (
    <Root>
      <SectionItemHead>
        <SectionItem>{userFullName}</SectionItem>
        <SectionItem>
          <FileLink href={data.pdfUrl} target="_blank" download>
            {data.title}
          </FileLink>
        </SectionItem>
        <SectionItem>
          {currency(data.sum, {
            precision: 0,
            separator: ' ',
            symbol: '₽',
            pattern: '# !',
            decimal: '.',
          }).format()}
        </SectionItem>
        <SectionItem>
          <Button size="small" onClick={() => setIfOpenForm(!isOpenForm)}>
            Изменить
          </Button>
        </SectionItem>
      </SectionItemHead>
      {isOpenForm && (
        <FormWrap>
          <StyledForm onSubmit={onSubmit}>
            <Input placeholder="Введите сумму" {...register('sum')} />
            <StyledButton
              disabled={!isDirty || !isValid}
              isLoading={isSubmitting}
            >
              Сохранить
            </StyledButton>
          </StyledForm>
        </FormWrap>
      )}
    </Root>
  );
};

const Root = styled.li`
  border: 1px solid ${COLORS.gray};
  border-radius: 8px;
  margin-bottom: 12px;
  :last-child {
    margin-bottom: 0;
  }
`;

const SectionItem = styled.div``;

const SectionItemHead = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 16px;
  align-items: center;
  padding: 10px;
`;

const FileLink = styled.a`
  color: ${COLORS.primaryDefault};
  max-width: 265px;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const FormWrap = styled.div`
  padding: 16px;
`;

const StyledForm = styled.form`
  max-width: 700px;
`;

const StyledButton = styled(Button)`
  margin-top: 12px;
`;

export default ContractItem;
