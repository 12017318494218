import { ContractResponseDto } from '@/api/generated';
import { COLORS } from '@/constants';
import React, { FC, useEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';
import styled from 'styled-components';
import { ContractList } from '../ContractList';

interface PaginatedItemsProps {
  itemsPerPage?: number;
  data: ContractResponseDto[];
}

const PaginatedItems: FC<PaginatedItemsProps> = ({
  data,
  itemsPerPage = 15,
}) => {
  const [currentItems, setCurrentItems] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);

  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;

    setCurrentItems(data.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(data.length / itemsPerPage));
  }, [itemOffset, itemsPerPage, data.length]);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % data.length;

    setItemOffset(newOffset);
  };

  return (
    <>
      <ContractList currentItems={currentItems} />
      <StyledReactPaginate
        breakLabel="..."
        nextLabel=" >"
        onPageChange={handlePageClick}
        pageRangeDisplayed={5}
        pageCount={pageCount}
        previousLabel="< "
        renderOnZeroPageCount={null}
      />
    </>
  );
};

const StyledReactPaginate = styled(ReactPaginate)`
  display: flex;
  justify-content: center;
  margin-top: 24px;
  padding: 0 16px;
  li a {
    border-radius: 7px;
    padding: 8px 12px;
    background-color: ${COLORS.white};
    color: ${COLORS.primaryDefault};
    cursor: pointer;
    min-width: 32px;
    transition: all 0.25s ease-in;
    margin-right: 5px;
    :hover {
      opacity: 0.7;
    }
  }
  li.selected a {
    background-color: ${COLORS.primaryDefault};
    color: ${COLORS.white};
  }
  li.disabled a {
    color: ${COLORS.gray};
  }
  li.disable,
  li.disabled a {
    cursor: default;
  }
`;

export default PaginatedItems;
