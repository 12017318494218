import { ContractResponseDto } from '@/api/generated';
import { Container } from '@/components';
import { TYPOGRAPHY } from '@/constants';
import { selectors } from '@/store/ducks';
import { adminGetContractsThunk } from '@/store/ducks/admin/thunks';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { TabData } from '@/types';
import { Tabs } from '@/ui/components';
import dayjs from 'dayjs';
import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import { ContractItem, PaginatedItems } from './components';

enum ActiveTab {
  all = 'all',
  filtered = 'filtered',
}

const tabData: TabData[] = [
  {
    text: 'Все',
    value: ActiveTab.all,
  },
  {
    text: 'Без суммы',
    value: ActiveTab.filtered,
  },
];

const AdminPage: FC = () => {
  const dispatch = useAppDispatch();
  const contracts = useAppSelector(selectors.admin.selectAdminContracts);
  const filteredContracts = useAppSelector(
    selectors.admin.selectAdminFilteredContracts
  );
  const [activeTab, setActiveTab] = useState<ActiveTab>(ActiveTab.filtered);
  const [sorteredByDESCContracts, setSorteredByDESCContracts] = useState<
    ContractResponseDto[]
  >([]);
  const [sorteredByDESCFilteredContracts, setSorteredByDESCFilteredContracts] =
    useState<ContractResponseDto[]>([]);

  useEffect(() => {
    dispatch(adminGetContractsThunk());
  }, []);

  useEffect(() => {
    const cloneContracts = [...contracts];
    const cloneFilteredContracts = [...filteredContracts];
    const sortedByDESCContracts = cloneContracts.sort((a, b) =>
      dayjs(b.updatedAt).isAfter(dayjs(a.updatedAt)) ? 1 : -1
    );

    setSorteredByDESCContracts(sortedByDESCContracts);

    const sortedByDESCFilteredContracts = cloneFilteredContracts.sort((a, b) =>
      dayjs(b.updatedAt).isAfter(dayjs(a.updatedAt)) ? 1 : -1
    );

    setSorteredByDESCFilteredContracts(sortedByDESCFilteredContracts);
  }, [contracts.length]);

  // console.log(contracts.map((item) => dayjs.utc(item.updatedAt).format()));

  return (
    <Container>
      <Title>Загруженные выписки</Title>
      <StyledTabs
        data={tabData}
        activeValue={activeTab}
        onToggle={setActiveTab}
      />
      <ContractsWrapper>
        <ContractsHead>
          <SectionItem>
            <p>Загрузил</p>
          </SectionItem>
          <SectionItem>
            <p>Файл</p>
          </SectionItem>
          <SectionItem>
            <p>Сумма</p>
          </SectionItem>
          <SectionItem>
            <p>Изменить сумму</p>
          </SectionItem>
        </ContractsHead>
        <TabContent>
          {activeTab === ActiveTab.all && (
            <PaginatedItems data={sorteredByDESCContracts} itemsPerPage={20} />
          )}
          {activeTab === ActiveTab.filtered && (
            <PaginatedItems data={sorteredByDESCFilteredContracts} />
          )}
        </TabContent>
      </ContractsWrapper>
    </Container>
  );
};

const Title = styled.h1`
  ${TYPOGRAPHY.title1SemiBold48}
  margin-bottom: 32px;
`;

const ContractsWrapper = styled.div`
  padding-bottom: 32px;
`;

const ContractsHead = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 16px;
  margin-bottom: 12px;
`;

const SectionItem = styled.div``;

const TabContent = styled.div``;

const StyledTabs = styled(Tabs)`
  margin-bottom: 32px;
`;

export default AdminPage;
