import { adminCreateManagerThunk } from '@/store/ducks/admin/thunks';
import { useAppDispatch } from '@/store/hooks';
import { Button, InputWithLabel } from '@/ui/components';
import React, { FC } from 'react';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';

interface FormData {
  firstName: string;
  lastName: string;
  middleName: string;
}

const CreateManager: FC = () => {
  const dispatch = useAppDispatch();
  const {
    register,
    handleSubmit,

    formState: { isSubmitting, isValid, isDirty },
    getFieldState,
  } = useForm<FormData>({
    mode: 'onChange',
  });

  const onSubmit = handleSubmit(async (data) => {
    try {
      const result = await dispatch(adminCreateManagerThunk(data)).unwrap();
    } catch (rejectedValueOrSerializedError) {}
  });
  return (
    <div>
      <StyledForm onSubmit={onSubmit}>
        <StyledInputWithLabel
          label="Фамилия"
          placeholder="Фамилия"
          {...register('lastName')}
        />
        <StyledInputWithLabel
          label="Имя"
          placeholder="Имя"
          {...register('firstName')}
        />
        <StyledInputWithLabel
          label="Отчество"
          placeholder="Отчество"
          {...register('middleName')}
        />
        <Button disabled={!isDirty || !isValid} isLoading={isSubmitting}>
          Добавить
        </Button>
      </StyledForm>
    </div>
  );
};

const StyledForm = styled.form`
  max-width: 800px;
`;

const StyledInputWithLabel = styled(InputWithLabel)`
  margin-bottom: 24px;
  :last-child {
    margin-bottom: 0;
  }
`;

export default CreateManager;
