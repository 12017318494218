import { selectors } from '@/store/ducks';
import { adminAddUserForManagerThunk } from '@/store/ducks/admin/thunks';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { Button } from '@/ui/components';
import React, { FC } from 'react';
import { Controller, useForm } from 'react-hook-form';
import ReactSelect, { Options } from 'react-select';
import styled from 'styled-components';

interface FormData {
  userId: Option;
  managerId: Option;
}

interface Option {
  value: string;
  label: string;
}

const AddUserForManager: FC = () => {
  const dispatch = useAppDispatch();
  const managers = useAppSelector(selectors.admin.selectAdminManagers);
  const users = useAppSelector(selectors.admin.selectAdminUsers);

  const managersOptions: Options<any> = managers.map((manager) => ({
    value: manager.id,
    label: `${manager.lastName} ${manager.firstName}`,
  }));

  const usersOptions: Options<any> = users.map((user) => ({
    value: user.id,
    label: `${user.lastName} ${user.firstName} ${user.middleName}`,
  }));

  const {
    handleSubmit,
    formState: { isSubmitting, isValid, isDirty },
    control,
  } = useForm<FormData>({
    mode: 'onChange',
  });

  const onSubmit = handleSubmit(async (data) => {
    const neededData = {
      userId: data.userId.value,
      managerId: data.managerId.value,
    };

    try {
      await dispatch(adminAddUserForManagerThunk(neededData)).unwrap();
    } catch (rejectedValueOrSerializedError) {}
  });

  return (
    <StyledForm onSubmit={onSubmit}>
      <SelectWrapper>
        <LabelSelect>Клиент</LabelSelect>
        <Controller
          name="userId"
          control={control}
          render={({ field }) => (
            <ReactSelect {...field} options={usersOptions} />
          )}
        />
      </SelectWrapper>
      <SelectWrapper>
        <LabelSelect>Менеджер</LabelSelect>
        <Controller
          name="managerId"
          control={control}
          render={({ field }) => (
            <ReactSelect {...field} options={managersOptions} />
          )}
        />
      </SelectWrapper>
      <StyledButton isLoading={isSubmitting} disabled={!isDirty || !isValid}>
        Добавить
      </StyledButton>
    </StyledForm>
  );
};

const StyledForm = styled.form`
  max-width: 800px;
`;

const SelectWrapper = styled.div`
  margin-bottom: 24px;
`;

const LabelSelect = styled.p`
  margin-bottom: 12px;
`;

const StyledButton = styled(Button)`
  margin-top: 32px;
`;

export default AddUserForManager;
