import { ContractResponseDto } from '@/api/generated';
import React, { FC } from 'react';
import styled from 'styled-components';
import { ContractItem } from '../ContractItem';

interface ContractListProps {
  currentItems: ContractResponseDto[];
}

const ContractList: FC<ContractListProps> = ({ currentItems }) => {
  return (
    <Root>
      {currentItems &&
        currentItems.map((contract) => {
          return <ContractItem key={contract.id} data={contract} />;
        })}
    </Root>
  );
};

const Root = styled.ul``;

export default ContractList;
