import { UserResponseDto } from '@/api/generated';
import { Container } from '@/components';
import { COLORS, TYPOGRAPHY } from '@/constants';
import { selectors } from '@/store/ducks';
import { adminGetUsersThunk } from '@/store/ducks/admin/thunks';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { Button } from '@/ui/components';
import { exportToCSV } from '@/utils/exportToCSV';
import dayjs from 'dayjs';
import React, { FC, useEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';
import styled from 'styled-components';

const UsersPage: FC = () => {
  const dispatch = useAppDispatch();
  const users = useAppSelector(selectors.admin.selectAdminUsers);

  const excelUsers = users.map((user) => ({
    'Зарегистрирован с': dayjs(user.createdAt).format('DD.MM.YYYY'),
    ФИО: `${user.lastName} ${user.firstName} ${user.middleName}`,
    'Дата рождения': dayjs(user.birthdate).format('DD.MM.YYYY'),
    СНИЛС: user.snils,
    Телефон: user.mobileNumber,
    'Рабочий телефон': user.workNumber || '',
    Email: user.email,
    Адрес: user.address,
    'Способ связи': user.communicationMethod,
    Менеджер: user.manager
      ? `${user.manager.lastName} ${user.manager.firstName} ${user.manager.middleName}`
      : '',
  }));
  const [currentItems, setCurrentItems] = useState<null | UserResponseDto[]>(
    null
  );
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const itemsPerPage = 15;

  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;

    setCurrentItems(users.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(users.length / itemsPerPage));
  }, [itemOffset, itemsPerPage, users.length]);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % users.length;

    setItemOffset(newOffset);
  };

  useEffect(() => {
    dispatch(adminGetUsersThunk());
  }, []);

  return (
    <StyledContainer>
      <TitleWrapper>
        <Title>Пользователи</Title>
        <DownloadExcel onClick={() => exportToCSV(excelUsers, 'Клиенты')}>
          Скачать
        </DownloadExcel>
      </TitleWrapper>
      <Head>
        <SectionItem>Имя</SectionItem>
        <SectionItem>Дата рождения</SectionItem>
        <SectionItem>Снилс</SectionItem>
        <SectionItem>Телефон</SectionItem>
        <SectionItem>Рабочий телефон</SectionItem>
        <SectionItem>E-mail</SectionItem>
        <SectionItem>Адрес</SectionItem>
        <SectionItem>Способ связи</SectionItem>
      </Head>
      <UsersList>
        {currentItems &&
          currentItems.map((item) => {
            return (
              <li key={item.id}>
                <SectionItem>
                  {item.lastName} {item.firstName} {item.middleName}
                </SectionItem>
                <SectionItem>
                  {dayjs(item.birthdate).format('DD.MM.YYYY')}
                </SectionItem>
                <SectionItem>{item.snils}</SectionItem>
                <SectionItem>
                  <a href={`tel: ${item.mobileNumber}`}>{item.mobileNumber}</a>
                </SectionItem>
                <SectionItem>
                  <a href={`tel: ${item.workNumber}`}>{item.workNumber}</a>
                </SectionItem>
                <SectionItem>
                  <a href={`mailto: ${item.email}`}>{item.email}</a>
                </SectionItem>
                <SectionItem>{item.address}</SectionItem>
                <SectionItem>{item.communicationMethod}</SectionItem>
              </li>
            );
          })}
      </UsersList>
      <StyledReactPaginate
        breakLabel="..."
        nextLabel=" >"
        onPageChange={handlePageClick}
        pageRangeDisplayed={5}
        pageCount={pageCount}
        previousLabel="< "
        renderOnZeroPageCount={null}
      />
    </StyledContainer>
  );
};

const StyledContainer = styled(Container)`
  max-width: 1600px;
  padding-bottom: 32px;
`;

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 32px;
`;

const Title = styled.h1`
  ${TYPOGRAPHY.title1SemiBold48};
  color: ${COLORS.black};
`;

const DownloadExcel = styled(Button)``;

const Head = styled.div`
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  gap: 12px;
  margin-bottom: 24px;
`;

const SectionItem = styled.div`
  ${TYPOGRAPHY.caption1Medium14};
  color: ${COLORS.black};
  a {
    color: ${COLORS.primaryDefault};
  }
`;

const UsersList = styled.ol`
  ${TYPOGRAPHY.body2Regular16};
  li {
    border: 1px solid ${COLORS.gray};
    border-radius: 12px;
    padding: 8px 12px;
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    gap: 12px;
    align-items: center;
    list-style: auto;
    margin-bottom: 12px;
    ${SectionItem} {
      color: ${COLORS.black2};
    }

    :last-child {
      margin-bottom: 0;
    }
  }
`;

const StyledReactPaginate = styled(ReactPaginate)`
  display: flex;
  justify-content: center;
  margin-top: 24px;
  padding: 0 16px;
  li a {
    border-radius: 7px;
    padding: 8px 12px;
    background-color: ${COLORS.white};
    color: ${COLORS.primaryDefault};
    cursor: pointer;
    min-width: 32px;
    transition: all 0.25s ease-in;
    margin-right: 5px;
    :hover {
      opacity: 0.7;
    }
  }
  li.selected a {
    background-color: ${COLORS.primaryDefault};
    color: ${COLORS.white};
  }
  li.disabled a {
    color: ${COLORS.gray};
  }
  li.disable,
  li.disabled a {
    cursor: default;
  }
`;

export default UsersPage;
