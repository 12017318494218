import { Container } from '@/components';
import { selectors } from '@/store/ducks';
import {
  adminGetManagersThunk,
  adminGetUsersThunk,
} from '@/store/ducks/admin/thunks';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { TabData } from '@/types';
import { Tabs } from '@/ui/components';
import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import {
  AddUserForManager,
  CreateManager,
  ManagerAccordion,
} from './components';

enum ManagersTab {
  create = 'create',
  all = 'all',
  addUserForManager = 'addUserForManager',
}

const tabData: TabData[] = [
  {
    text: 'Все менеджеры',
    value: ManagersTab.all,
  },

  {
    text: 'Создать менеджера',
    value: ManagersTab.create,
  },
  {
    text: 'Добавить клиента менеджеру',
    value: ManagersTab.addUserForManager,
  },
];

const ManagersPage: FC = () => {
  const dispatch = useAppDispatch();
  const managers = useAppSelector(selectors.admin.selectAdminManagers);
  const users = useAppSelector(selectors.admin.selectAdminUsers);

  const [activeTab, setActiveTab] = useState<ManagersTab>(ManagersTab.all);

  useEffect(() => {
    dispatch(adminGetManagersThunk());
    dispatch(adminGetUsersThunk());
  }, []);

  return (
    <Container>
      <StyledTabs
        data={tabData}
        activeValue={activeTab}
        onToggle={setActiveTab}
      />
      <TabContent>
        {activeTab === ManagersTab.all && (
          <div>
            {managers.map((manager) => {
              return <StyledManagerAccordion key={manager.id} data={manager} />;
            })}
          </div>
        )}
        {activeTab === ManagersTab.create && <CreateManager />}
        {activeTab === ManagersTab.addUserForManager && <AddUserForManager />}
      </TabContent>
    </Container>
  );
};

const StyledTabs = styled(Tabs)`
  margin-bottom: 32px;
`;

const TabContent = styled.div`
  padding-bottom: 32px;
`;

const StyledManagerAccordion = styled(ManagerAccordion)`
  margin-bottom: 24px;
  :last-child {
    margin-bottom: 0;
  }
`;

export default ManagersPage;
