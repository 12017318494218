import React from 'react';
import { Router } from '@reach/router';
import { PrivateAdminRoute, Seo } from '@/components';
import { AdminLayout } from '@/layouts';
import retireds from '@/assets/images/retireds.png';
import { SeoData } from '@/types';
import { AdminPage } from '@/pageComponents/AdminPage';
import { ManagersPage } from '@/pageComponents/ManagersPage';
import { AdminLoginPage } from '@/pageComponents/AdminLoginPage';
import { UsersPage } from '@/pageComponents/UsersPage';

export default function Admin() {
  const seoData: SeoData = {
    metaTitle: 'Социальный проект «Выплаты»',
    metaDescription: 'Социальный проект «Выплаты»',
    keywords: 'пенсия, деньги, выплата',
    sharedImage: {
      url: retireds,
    },
  };

  return (
    <AdminLayout>
      <Seo data={seoData} />

      <Router basepath="/admin">
        <PrivateAdminRoute path="/dashboard" component={AdminPage} />
        <PrivateAdminRoute path="/managers" component={ManagersPage} />
        <PrivateAdminRoute path="/users" component={UsersPage} />
        <AdminLoginPage path="/login" />
      </Router>
    </AdminLayout>
  );
}
