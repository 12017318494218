import { Container } from '@/components';

import { adminSignInThunk } from '@/store/ducks/admin/thunks';
import { useAppDispatch } from '@/store/hooks';
import { Button, InputWithLabel } from '@/ui/components';
import { navigate } from 'gatsby';
import React, { FC } from 'react';
import { useForm } from 'react-hook-form';

import styled from 'styled-components';

interface FormData {
  login: string;
  password: string;
}

const AdminLoginPage: FC<any> = () => {
  const dispatch = useAppDispatch();
  const {
    register,
    handleSubmit,

    formState: { isSubmitting, isValid, isDirty },
    getFieldState,
  } = useForm<FormData>({
    mode: 'onChange',
  });

  const onSubmit = handleSubmit(async (data) => {
    try {
      const result = await dispatch(adminSignInThunk(data)).unwrap();
      if (result) {
        navigate('/admin/dashboard');
      }
    } catch (rejectedValueOrSerializedError) {}
  });

  return (
    <Container>
      <StyledForm onSubmit={onSubmit}>
        <StyledInputWithLabel
          label="Логин"
          placeholder="Логин"
          {...register('login')}
        />
        <StyledInputWithLabel
          label="Пароль"
          type="password"
          placeholder="Пароль"
          {...register('password')}
        />
        <StyledButton disabled={!isDirty || !isValid} isLoading={isSubmitting}>
          Войти
        </StyledButton>
      </StyledForm>
    </Container>
  );
};

const StyledInputWithLabel = styled(InputWithLabel)`
  margin-bottom: 24px;
  width: 100%;
`;

const StyledButton = styled(Button)``;

const StyledForm = styled.form`
  margin: 0 auto;
  max-width: 800px;
`;

export default AdminLoginPage;
