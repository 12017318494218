import { ManagerResponseDto } from '@/api/generated';
import { COLORS, TYPOGRAPHY } from '@/constants';
import { Arrow } from '@/ui/iconComponents';
import dayjs from 'dayjs';
import React, { FC, useState } from 'react';
import styled from 'styled-components';

interface ManagerAccordionProps {
  data: ManagerResponseDto;
  className?: string;
}

const ManagerAccordion: FC<ManagerAccordionProps> = ({ data, className }) => {
  const [isOpenInfo, setIsOpenInfo] = useState(false);
  const fullName = `${data.lastName} ${data.firstName} ${data.middleName}`;

  return (
    <Root className={`${className} ${isOpenInfo && 'active'}`}>
      <Wrapper>
        <div>
          <Button onClick={() => setIsOpenInfo(!isOpenInfo)}>
            {fullName}
            <ArrowWrapper>
              <StyledArrow color={COLORS.white} />
            </ArrowWrapper>
          </Button>
        </div>
        {isOpenInfo && data.users && (
          <UsersListWrapper>
            <UsersListHead>
              <p>ФИО</p>
              <p>Телефон</p>
              <p>Email</p>
              <p>Способ связи</p>
              <p>Зарегистрирован с</p>
            </UsersListHead>
            <UsersList>
              {data.users.map((user) => {
                const userFullName = `${user.lastName} ${user.firstName} ${user.middleName}`;
                return (
                  <UserItem key={user.id}>
                    <UserName>{userFullName}</UserName>
                    {user.mobileNumber && (
                      <UserLink href={`tel: ${user.mobileNumber}`}>
                        {user.mobileNumber}
                      </UserLink>
                    )}
                    {user.email && (
                      <UserLink href={`mailto: ${user.email}`}>
                        {user.email}
                      </UserLink>
                    )}
                    <CommunicationMethod>
                      {user.communicationMethod
                        ? user.communicationMethod
                        : 'Не указан'}
                    </CommunicationMethod>
                    <CreateAccountDate>
                      {dayjs(user.createdAt).format('DD.MM.YYYY')}
                    </CreateAccountDate>
                  </UserItem>
                );
              })}
            </UsersList>
          </UsersListWrapper>
        )}
      </Wrapper>
    </Root>
  );
};

const Root = styled.div`
  background-color: ${COLORS.grayLighter};
  border-radius: 20px;
`;

const Wrapper = styled.div`
  padding: 20px;
  @media (max-width: 768px) {
    padding: 30px 15px;
  }
`;

const ArrowWrapper = styled.span`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 40px;
  height: 40px;
  background-color: ${COLORS.primaryDefault};
  transition: background-color 0.3s ease;
  border-radius: 8px;
  margin-left: 12px;
`;

const StyledArrow = styled(Arrow)`
  transform: rotate(90deg);
  transition: transform 0.3s ease;
  .active & {
    transform: rotate(270deg);
  }
`;

const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;

  ${TYPOGRAPHY.headline2SemiBold22};
  color: ${COLORS.black};
  margin-right: 20px;
  width: 100%;
  cursor: pointer;
  text-align: left;
`;

const UsersListWrapper = styled.div`
  padding-top: 32px;
`;

const UsersListHead = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 12px;
  margin-bottom: 24px;
  padding-left: 16px;
`;

const UsersList = styled.ol`
  counter-reset: users;
`;

const UserItem = styled.li`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 12px;
  align-items: center;
  padding-left: 16px;
  position: relative;
  ${TYPOGRAPHY.body2Regular16};
  margin-bottom: 8px;
  :last-child {
    margin-bottom: 0;
  }
  ::before {
    counter-increment: users;
    content: counter(users) '.';
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
  }
`;

const UserName = styled.p``;

const UserLink = styled.a`
  color: ${COLORS.primaryDefault};
`;

const CreateAccountDate = styled.p``;

const CommunicationMethod = styled.p``;

export default ManagerAccordion;
