import {
  AddManagerRequestDto,
  AdminSignInRequestDto,
  AdminSignInResponseDto,
  AdminUpdateContractDto,
  ContractResponseDto,
  CreateManagerRequestDto,
  ManagerResponseDto,
  UserResponseDto,
} from '@/api/generated';
import { adminHttp } from '@/services/adminHttp';
import { http } from '@/services/http';
import { handleAsyncError } from '@/utils/handleAsyncError';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError, AxiosResponse } from 'axios';
import { toast } from 'react-toastify';
import {
  addManager,
  addUserForManager,
  setContracts,
  setManagers,
  setToken,
  setUsers,
  updateContract,
} from './actions';
import { ActionType } from './types';

export const adminSignInThunk = createAsyncThunk<
  AdminSignInResponseDto,
  AdminSignInRequestDto
>(ActionType.SIGN_IN, async (values, { dispatch }) => {
  try {
    const { data }: AxiosResponse<AdminSignInResponseDto> =
      await adminHttp.post('/sign-in', values);

    dispatch(setToken(data));

    return data;
  } catch (e) {
    const error = e as AxiosError;
    handleAsyncError(error);
  }
});

export const adminGetManagersThunk = createAsyncThunk<void, void>(
  ActionType.GET_MANAGERS,
  async (_, { dispatch }) => {
    try {
      const { data }: AxiosResponse<ManagerResponseDto[]> = await adminHttp.get(
        '/managers'
      );

      dispatch(setManagers(data));
    } catch (e) {
      const error = e as AxiosError;
      handleAsyncError(error);
    }
  }
);

export const adminGetUsersThunk = createAsyncThunk<void, void>(
  ActionType.GET_USERS,
  async (_, { dispatch }) => {
    try {
      const { data }: AxiosResponse<UserResponseDto[]> = await adminHttp.get(
        '/users'
      );

      dispatch(setUsers(data));
    } catch (e) {
      const error = e as AxiosError;
      handleAsyncError(error);
    }
  }
);

export const adminGetContractsThunk = createAsyncThunk<void, void>(
  ActionType.GET_ALL_CONTRACTS,
  async (_, { dispatch }) => {
    try {
      const { data }: AxiosResponse<ContractResponseDto[]> =
        await adminHttp.get('/contracts');

      dispatch(setContracts(data));
    } catch (e) {
      const error = e as AxiosError;
      handleAsyncError(error);
    }
  }
);

export const adminCreateManagerThunk = createAsyncThunk<
  void,
  CreateManagerRequestDto
>(ActionType.GET_MANAGERS, async (values, { dispatch }) => {
  try {
    const { data }: AxiosResponse<ManagerResponseDto> = await adminHttp.post(
      '/manager',
      values
    );
    toast.success('Менеджер создан');
    dispatch(addManager(data));
  } catch (e) {
    const error = e as AxiosError;
    handleAsyncError(error);
  }
});

export const adminAddUserForManagerThunk = createAsyncThunk<
  void,
  AddManagerRequestDto
>(ActionType.GET_MANAGERS, async (values, { dispatch }) => {
  try {
    const { data }: AxiosResponse<UserResponseDto> = await adminHttp.patch(
      '/user/add-manager',
      values
    );
    toast.success('Клиент добавлен к менеджеру');
    dispatch(addUserForManager(values));
  } catch (e) {
    const error = e as AxiosError;
    handleAsyncError(error);
  }
});

export const adminUpdateContractThunk = createAsyncThunk<
  void,
  AdminUpdateContractDto
>(ActionType.PATCH_UPDATE_CONTRACT, async (values, { dispatch }) => {
  try {
    const { data }: AxiosResponse<ContractResponseDto> = await adminHttp.patch(
      '/contract',
      values
    );
    toast.success('Данные выписки обновлены');
    dispatch(updateContract(data));
  } catch (e) {
    const error = e as AxiosError;
    handleAsyncError(error);
  }
});
